import { useMemo } from "react";

import { API_ENDPOINTS } from "../../api/constants";
import useFetch from "../../api/useFetch";

/*
 * Using a company ID, provide a useful object of booleans that indicate the level of access
 * the user has to the company's data.
 */
const useCompanyAccess = (companyId) => {
  const hasProDataAccessQueryInfo = useFetch(
    API_ENDPOINTS.HAS_PRO_DATA_ACCESS(companyId),
    {},
    {
      enabled: Boolean(companyId),
    }
  );

  return useMemo(() => {
    if (
      hasProDataAccessQueryInfo.isLoading ||
      hasProDataAccessQueryInfo.isError ||
      !hasProDataAccessQueryInfo.cleanedData
    ) {
      return {
        canDownloadPDFReport: false,
        canDownloadZXData: false,
        canViewBidAskPriceGraph: false,
        canViewBidAskRatioGraph: false,
        canViewPriceAndVolumeGraph: false,
        canViewRelativeActivityGraph: false,
        canViewReportedMarksGraph: false,
        canViewSacraDocuments: false,
        isLoading: hasProDataAccessQueryInfo.isLoading,
        isError: hasProDataAccessQueryInfo.isError,
        hasAnyAccess: false,
        timeFrame: null,
      };
    }
    return {
      canDownloadPDFReport:
        hasProDataAccessQueryInfo.cleanedData.can_download_pdf_report,
      canDownloadZXData:
        hasProDataAccessQueryInfo.cleanedData.can_download_zx_data,
      canViewBidAskPriceGraph:
        hasProDataAccessQueryInfo.cleanedData.can_view_bid_ask_price_graph,
      canViewBidAskRatioGraph:
        hasProDataAccessQueryInfo.cleanedData.can_view_bid_ask_ratio_graph,
      canViewPriceAndVolumeGraph:
        hasProDataAccessQueryInfo.cleanedData.can_view_price_and_volume_graph,
      canViewRelativeActivityGraph:
        hasProDataAccessQueryInfo.cleanedData.can_view_relative_activity_graph,
      canViewReportedMarksGraph:
        hasProDataAccessQueryInfo.cleanedData.can_view_reported_marks_graph,
      canViewSacraDocuments:
        hasProDataAccessQueryInfo.cleanedData.can_view_sacra_documents,
      isLoading: hasProDataAccessQueryInfo.isLoading,
      isError: hasProDataAccessQueryInfo.isError,
      hasAnyAccess: Object.keys(hasProDataAccessQueryInfo.cleanedData)
        .filter((key) => key.startsWith("can_"))
        .some((key) => hasProDataAccessQueryInfo.cleanedData[key]),
      timeFrame: hasProDataAccessQueryInfo.cleanedData.time_frame,
    };
  }, [hasProDataAccessQueryInfo]);
};

export default useCompanyAccess;
